import { Canvas } from "@react-three/fiber";
import React, { Suspense, useRef } from "react";
import Model from "./components/Face";

export const ShowFace = () => {
    const canvas = useRef<HTMLCanvasElement>(null);
    return (
        <div className="h-full">
            <Canvas camera={{ fov: 40, position: [0, 0, 7] }} ref={canvas}>
                <pointLight position={[0, 20, 100]} intensity={0.5} />
                <Suspense fallback={null}>
                    <Model canvas={canvas} />
                </Suspense>
            </Canvas>
        </div>
    );
};
