import React, { Suspense } from "react";
import Envelope from "../../static/svg/envelope.svg";
import Github from "../../static/svg/github.svg";
import Linkedin from "../../static/svg/linkedin.svg";
import { ShowFace } from "../components/showFace/showFace";

export default function Index() {
    return (
        <div className="container mx-auto">
            <article className="h-screen">
                <div className="mx-auto top-1/2 relative md:flex justify-center h-256 transform -translate-y-1/2">
                    <section className="mb-8 md:mb-0">
                        <Suspense
                            fallback={<img src="/fallback.png" alt="my face" />}
                        >
                            <ShowFace />
                        </Suspense>
                    </section>
                    <section className="text-center md:text-left">
                        <h1 className="text-4xl md:text-6xl mb-8 font-light tracking-tighter text-gray-300">
                            Alvaro Tinoco
                        </h1>
                        <div className="text-md md:text-xl mb-8 text-gray-300">
                            <p>Full Stack Web Developer.</p>
                            <p>Future SRE.</p>
                        </div>
                        <div className="inline-flex text-gray-500">
                            <a
                                href="mailto:atinoco@protonmail.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Envelope className="h-8 md:h-10 mr-2 hover:text-blue-500" />
                            </a>
                            <a
                                href="https://github.com/MrMarble"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Github className="h-8 md:h-10 mr-2 hover:text-blue-500" />
                            </a>
                            <a
                                href="https://www.linkedin.com/in/alvaro-tinoco-marmol/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Linkedin className="h-8 md:h-10 hover:text-blue-500" />
                            </a>
                        </div>
                    </section>
                </div>
            </article>
        </div>
    );
}
