import { useGLTF } from "@react-three/drei";
import React, { RefObject } from "react";
import { useFace } from "./useFace";

const MODEL = "./face.glb";

type ModelProps = JSX.IntrinsicElements["group"] & {
    canvas: RefObject<HTMLCanvasElement>;
};

export default function Model({ canvas, ...props }: ModelProps) {
    const { group, nodes, materials } = useFace(MODEL, canvas);
    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.Head.geometry}
                material={materials["Material.006"]}
                position={[-0.2, -0.74, 0.05]}
                scale={1.41}
                material-color="#F6C6AF"
            />
            <mesh
                geometry={nodes.Glasses.geometry}
                material={materials["Material.002"]}
                position={[-0.93, -0.29, 1.4]}
                rotation={[-0.1, 0, 0]}
                scale={[0.5, 0.55, 0.07]}
                material-color="#C1C1C1"
            />
            <mesh
                geometry={nodes.Mostache.geometry}
                material={nodes.Mostache.material}
                position={[-0.26, -1.02, 1.51]}
                rotation={[0.32, 0, 0]}
            />
            <mesh
                geometry={nodes.Nose.geometry}
                material={nodes.Nose.material}
                position={[-0.2, -0.45, 1.45]}
                rotation={[-0.27, 0, 0]}
                scale={[0.16, 0.39, 0.14]}
            />
            <mesh
                geometry={nodes.Hair.geometry}
                material={materials["Material.005"]}
                position={[-0.17, 0.33, -0.06]}
                scale={[-1, 1, 1]}
            />
            <mesh
                geometry={nodes.Lips.geometry}
                material={nodes.Lips.material}
                position={[-0.26, -1.13, 1.5]}
                rotation={[0.32, 0, 0]}
            />
            <mesh
                geometry={nodes.Eyebrows.geometry}
                material={nodes.Eyebrows.material}
                position={[-0.26, 0.55, 1.6]}
                scale={1.07}
            />
            <mesh
                geometry={nodes.Eyes.geometry}
                material={materials["Material.001"]}
                position={[0.38, -0.24, 1.37]}
                scale={[0.1, 0.12, 0.06]}
            />
            <mesh
                geometry={nodes.Beard.geometry}
                material={nodes.Beard.material}
                position={[-0.26, -1.06, 1.57]}
                rotation={[0.32, 0, 0]}
                scale={[1, 1, 0.94]}
            />
        </group>
    );
}

useGLTF.preload(MODEL);
