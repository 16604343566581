import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { RefObject, useEffect, useRef } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import type * as THREE from "three";
import { Vector2, Vector3 } from "three";
import type { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

type GLTFResult = GLTF & {
    nodes: {
        Head: THREE.Mesh;
        Glasses: THREE.Mesh;
        Mostache: THREE.Mesh;
        Nose: THREE.Mesh;
        Hair: THREE.Mesh;
        Lips: THREE.Mesh;
        Eyebrows: THREE.Mesh;
        Eyes: THREE.Mesh;
        Beard: THREE.Mesh;
    };
    materials: {
        ["Material.006"]: THREE.MeshStandardMaterial;
        ["Material.002"]: THREE.MeshStandardMaterial;
        ["Material.004"]: THREE.MeshStandardMaterial;
        ["Material.003"]: THREE.MeshStandardMaterial;
        ["Material.005"]: THREE.MeshStandardMaterial;
        ["Material.003"]: THREE.MeshStandardMaterial;
        ["Material.001"]: THREE.MeshStandardMaterial;
    };
};

export const useFace = (
    modelPath: string,
    canvas: RefObject<HTMLCanvasElement>
) => {
    const group = useRef<THREE.Group>();
    const clientMouse = useRef<{ clientX: number; clientY: number }>({
        clientX: window.innerWidth / 2,
        clientY: window.innerHeight / 2,
    });

    const canvasOffset = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

    const { nodes, materials } = useGLTF(modelPath) as unknown as GLTFResult;

    let isFollowing = false;

    useFrame(({ clock }) => {
        const mouse = new Vector2();
        const {
            current: { clientX, clientY },
        } = clientMouse;
        const {
            current: { x: canvasX, y: canvasY },
        } = canvasOffset;

        mouse.x = ((clientX + canvasX) / window.innerWidth) * 2 - 1;
        mouse.y = -((clientY + canvasY) / window.innerHeight) * 2 + 1;

        if (group.current) {
            if (isMobile || !isFollowing) {
                group.current.rotation.y = clock.getElapsedTime();
            }
            if (isDesktop && mouse.x !== 0 && mouse.y !== 0) {
                isFollowing = true;
                const vector = new Vector3(mouse.x, mouse.y, 1);
                group.current.lookAt(vector);
            }
        }
    });

    const trackMouse = ({ clientX, clientY }: MouseEvent) => {
        clientMouse.current = { clientX, clientY };
    };

    const calculateOffset = () => {
        if (canvas.current) {
            const rect = canvas.current.getBoundingClientRect();

            const relativeX = rect.left + rect.width / 2;
            const relativeY = rect.top + rect.height / 2;
            const x = window.innerWidth / 2 - relativeX;
            const y = window.innerHeight / 2 - relativeY;

            canvasOffset.current = {
                x,
                y,
            };
        }
    };

    useEffect(() => {
        calculateOffset();
        document.addEventListener("mousemove", trackMouse);
        document.addEventListener("resize", calculateOffset);

        return () => {
            document.removeEventListener("mousemove", trackMouse);
            document.removeEventListener("resize", calculateOffset);
        };
    }, []);

    return {
        group,
        nodes,
        materials,
    };
};
